import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';
import queryString from 'query-string';

export const getSubject = createAsyncThunk('subjectSlice/getSubject', async () => {
    try {
        let response = await axios.get('/subject', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSubjectOptions = createAsyncThunk('subjectSlice/getSubjectOptions', async (data = {}) => {
    try {
        // showLoader(true);

        data.status = 1;
        data.option = 'true';
        const stringified = queryString.stringify(data, { arrayFormat: 'bracket' });

        let response = await axios.get('/subject?' + stringified, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createSubject = createAsyncThunk('subjectSlice/createSubject', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/subject', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Subject added successfully.');
        dispatch(getSubject());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSubjectById = createAsyncThunk('subjectSlice/getSubjectById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/subject/' + id);

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSubject = createAsyncThunk('subjectSlice/updateSubject', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/subject/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Subject updated successfully.');
        dispatch(getSubject());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteSubject = createAsyncThunk('subjectSlice/deleteSubject', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/subject/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Subject deleted successfully.');
        dispatch(getSubject());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('subjectSlice/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/subject/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getSubject());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const subjectSlice = createSlice({
    name: 'subjectSlice',
    initialState: {
        data: [],
        options: [],
        selectedSubject: {}
    },
    reducers: {
        handleSelectedSubject: (state, action) => {
            state.selectedSubject = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSubject.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getSubjectOptions.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
        builder.addCase(getSubjectById.fulfilled, (state, action) => {
            state.selectedSubject = action.payload;
            return;
        });
    }
});

export const { handleSelectedSubject } = subjectSlice.actions;

export default subjectSlice.reducer;
