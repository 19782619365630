// Secure ls
import SecureLS from 'secure-ls';
import { compressToUTF16, decompressFromUTF16 } from 'lz-string';

export const secureLs = new SecureLS({ encodingType: 'aes', encryptionSecret: 'timetable-admin-project', isCompression: false });
export const secureSet = (key, value) => {
    try {
        const data = compressToUTF16(JSON.stringify(value));
        secureLs.set(key, data);
        return true;
    } catch (err) {
        console.log('secureSet Error:', err.message);
        return false;
    }
};

export const secureGet = (key) => {
    try {
        let data = secureLs.get(key);
        if (data) return JSON.parse(decompressFromUTF16(data));
        return null;
    } catch (err) {
        console.log('secureGet Error:', key, err.message);
        return null;
    }
};

export default secureLs;
