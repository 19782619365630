import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';
import queryString from 'query-string';

export const getRoom = createAsyncThunk('roomSlice/getRoom ', async () => {
    try {
        let response = await axios.get('/room', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getRoomOptions = createAsyncThunk('roomSlice/getRoom Options', async (data = {}) => {
    try {
        // showLoader(true);

        data.status = 1;
        data.option = 'true';
        const stringified = queryString.stringify(data, { arrayFormat: 'bracket' });

        let response = await axios.get('/room?' + stringified, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createRoom = createAsyncThunk('roomSlice/createRoom ', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/room', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Room added successfully.');
        dispatch(getRoom());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getRoomById = createAsyncThunk('roomSlice/getRoom ById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/room/' + id);

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateRoom = createAsyncThunk('roomSlice/updateRoom ', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/room/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Room updated successfully.');
        dispatch(getRoom());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteRoom = createAsyncThunk('roomSlice/deleteRoom ', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/room/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Room deleted successfully.');
        dispatch(getRoom());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('roomSlice/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/room/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getRoom());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const roomSlice = createSlice({
    name: 'roomSlice',
    initialState: {
        data: [],
        options: [],
        selectedRoom: {}
    },
    reducers: {
        handleSelectedRoom: (state, action) => {
            state.selectedRoom = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRoom.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getRoomOptions.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
        builder.addCase(getRoomById.fulfilled, (state, action) => {
            state.selectedRoom = action.payload;
            return;
        });
    }
});

export const { handleSelectedRoom } = roomSlice.actions;

export default roomSlice.reducer;
