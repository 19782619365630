import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getBatchType = createAsyncThunk('batchTypeSlice/getBatchType', async () => {
    try {
        let response = await axios.get('/batch-type', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBatchTypeOptions = createAsyncThunk('batchTypeSlice/getBatchTypeOptions', async () => {
    try {
        // showLoader(true);

        let response = await axios.get('/batch-type?status=1&option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBatchType = createAsyncThunk('batchTypeSlice/createBatchType', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/batch-type', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'BatchType added successfully.');
        dispatch(getBatchType());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBatchTypeById = createAsyncThunk('batchTypeSlice/getBatchTypeById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/batch-type/' + id);

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBatchType = createAsyncThunk('batchTypeSlice/updateBatchType', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/batch-type/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'BatchType updated successfully.');
        dispatch(getBatchType());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteBatchType = createAsyncThunk('batchTypeSlice/deleteBatchType', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/batch-type/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'BatchType deleted successfully.');
        dispatch(getBatchType());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('batchTypeSlice/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/batch-type/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getBatchType());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const batchTypeSlice = createSlice({
    name: 'batchTypeSlice',
    initialState: {
        data: [],
        options: [],
        selectedBatchType: {}
    },
    reducers: {
        handleSelectedBatchType: (state, action) => {
            state.selectedBatchType = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getBatchType.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getBatchTypeOptions.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
        builder.addCase(getBatchTypeById.fulfilled, (state, action) => {
            state.selectedBatchType = action.payload;
            return;
        });
    }
});

export const { handleSelectedBatchType } = batchTypeSlice.actions;

export default batchTypeSlice.reducer;
