import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getBatch = createAsyncThunk('batchSlice/getBatch', async () => {
    try {
        let response = await axios.get('/batch', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBatchOptions = createAsyncThunk('batchSlice/getBatchOptions', async () => {
    try {
        // showLoader(true);

        let response = await axios.get('/batch?status=1', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // showLoader(false);
        return Promise.resolve(response);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBatch = createAsyncThunk('batchSlice/createBatch', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/batch', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Batch added successfully.');
        dispatch(getBatch());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBatchById = createAsyncThunk('batchSlice/getBatchById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/batch/' + id);

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBatch = createAsyncThunk('batchSlice/updateBatch', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/batch/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Batch updated successfully.');
        dispatch(getBatch());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteBatch = createAsyncThunk('batchSlice/deleteBatch', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/batch/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Batch deleted successfully.');
        dispatch(getBatch());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('batchSlice/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/batch/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getBatch());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return false;
    }
});

export const addTimingSlotToBatch = createAsyncThunk('batchSlice/addTimingSlotToBatch', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/timetable/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Timing Slot added successfully.');
        dispatch(getBatchById(data.id));

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        console.log(err);
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteTimingSlotFromBatch = createAsyncThunk('batchSlice/deleteTimingSlotFromBatch', async (id, { dispatch, getState }) => {
    try {
        showLoader(true);

        let response = await axios.delete(`/timetable/${id}?check=yes`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Timing Slot deleted successfully.');

        const state = getState();
        dispatch(getBatchById(state.batch.selectedBatch.id));

        showLoader(false);
        return Promise.resolve(response);
    } catch (err) {
        console.log(err);
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const batchSlice = createSlice({
    name: 'batchSlice',
    initialState: {
        data: [],
        options: [],
        selectedBatch: {}
    },
    reducers: {
        handleSelectedBatch: (state, action) => {
            state.selectedBatch = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getBatch.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getBatchOptions.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
        builder.addCase(getBatchById.fulfilled, (state, action) => {
            state.selectedBatch = action.payload;
            return;
        });
    }
});

export const { handleSelectedBatch } = batchSlice.actions;

export default batchSlice.reducer;
