import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

import queryString from 'query-string';

export const getDepartment = createAsyncThunk('departmentSlice/getDepartment', async () => {
    try {
        let response = await axios.get('/department', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDepartmentOptions = createAsyncThunk('departmentSlice/getDepartmentOptions', async (data = {}) => {
    try {
        // showLoader(true);

        data.status = 1;
        data.option = 'true';
        const stringified = queryString.stringify(data, { arrayFormat: 'bracket' });

        let response = await axios.get('/department?' + stringified, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createDepartment = createAsyncThunk('departmentSlice/createDepartment', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/department', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Department added successfully.');
        dispatch(getDepartment());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDepartmentById = createAsyncThunk('departmentSlice/getDepartmentById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/department/' + id);

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateDepartment = createAsyncThunk('departmentSlice/updateDepartment', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/department/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Department updated successfully.');
        dispatch(getDepartment());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteDepartment = createAsyncThunk('departmentSlice/deleteDepartment', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/department/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Department deleted successfully.');
        dispatch(getDepartment());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('departmentSlice/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/department/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getDepartment());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return false;
    }
});

export const departmentSlice = createSlice({
    name: 'departmentSlice',
    initialState: {
        data: [],
        options: [],
        selectedDepartment: {}
    },
    reducers: {
        handleSelectedDepartment: (state, action) => {
            state.selectedDepartment = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getDepartment.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getDepartmentOptions.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
        builder.addCase(getDepartmentById.fulfilled, (state, action) => {
            state.selectedDepartment = action.payload;
            return;
        });
    }
});

export const { handleSelectedDepartment } = departmentSlice.actions;

export default departmentSlice.reducer;
