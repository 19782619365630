import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';
import queryString from 'query-string';

export const getTimetable = createAsyncThunk('timetableSlice/getTimetable', async (data = {}) => {
    try {
        let response = await axios.post('/timetable', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getTimetableRoomWise = createAsyncThunk('timetableSlice/getTimetableRoomWise', async (data = {}) => {
    try {
        let response = await axios.post('/timetable/room', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateTimetable = createAsyncThunk('timetableSlice/updateTimetable', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/timetable/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Timetable updated successfully.');

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteTimetable = createAsyncThunk('timetableSlice/deleteTimetable', async (data = {}, { dispatch, getState }) => {
    try {
        showLoader(true);

        const options = {};
        if (data.check === true) options.check = 'yes';

        const stringified = queryString.stringify(options, { arrayFormat: 'bracket' });

        let response = await axios.delete(`/timetable/${data.id}?${stringified}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Timing Slot deleted successfully.');

        const state = getState();
        dispatch(getTimetable());

        showLoader(false);
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const timetableSlice = createSlice({
    name: 'timetableSlice',
    initialState: {
        data: [],
        selected: {}
    },
    reducers: {
        handleSelectedTimetable: (state, action) => {
            state.selected = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTimetable.fulfilled, (state, action) => {
            state.data = action.payload.data;
            return;
        });
    }
});

export const { handleSelectedTimetable } = timetableSlice.actions;

export default timetableSlice.reducer;
