import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';
import queryString from 'query-string';

export const getBranch = createAsyncThunk('branchSlice/getBranch', async () => {
    try {
        let response = await axios.get('/branch', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBranchOptions = createAsyncThunk('branchSlice/getBranchOptions', async (data = {}) => {
    try {
        // showLoader(true);

        data.status = 1;
        data.option = 'true';
        const stringified = queryString.stringify(data, { arrayFormat: 'bracket' });

        let response = await axios.get('/branch?' + stringified, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBranch = createAsyncThunk('branchSlice/createBranch', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/branch', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Branch added successfully.');
        dispatch(getBranch());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBranchById = createAsyncThunk('branchSlice/getBranchById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/branch/' + id);

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBranch = createAsyncThunk('branchSlice/updateBranch', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/branch/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Branch updated successfully.');
        dispatch(getBranch());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteBranch = createAsyncThunk('branchSlice/deleteBranch', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/branch/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Branch deleted successfully.');
        dispatch(getBranch());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('branchSlice/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/branch/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getBranch());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return false;
    }
});

export const branchSlice = createSlice({
    name: 'branchSlice',
    initialState: {
        data: [],
        options: [],
        selectedBranch: {}
    },
    reducers: {
        handleSelectedBranch: (state, action) => {
            state.selectedBranch = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getBranch.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getBranchOptions.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
        builder.addCase(getBranchById.fulfilled, (state, action) => {
            state.selectedBranch = action.payload;
            return;
        });
    }
});

export const { handleSelectedBranch } = branchSlice.actions;

export default branchSlice.reducer;
