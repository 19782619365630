import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getCluster = createAsyncThunk('clusterSlice/getCluster', async () => {
    try {
        let response = await axios.get('/cluster', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getClusterOptions = createAsyncThunk('clusterSlice/getClusterOptions', async () => {
    try {
        // showLoader(true);

        let response = await axios.get('/cluster?status=1&option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createCluster = createAsyncThunk('clusterSlice/createCluster', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/cluster', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Cluster added successfully.');
        dispatch(getCluster());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getClusterById = createAsyncThunk('clusterSlice/getClusterById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/cluster/' + id);

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateCluster = createAsyncThunk('clusterSlice/updateCluster', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/cluster/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Cluster updated successfully.');
        dispatch(getCluster());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteCluster = createAsyncThunk('clusterSlice/deleteCluster', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/cluster/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Cluster deleted successfully.');
        dispatch(getCluster());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('clusterSlice/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/cluster/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getCluster());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const clusterSlice = createSlice({
    name: 'clusterSlice',
    initialState: {
        data: [],
        options: [],
        selectedCluster: {}
    },
    reducers: {
        handleSelectedCluster: (state, action) => {
            state.selectedCluster = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCluster.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getClusterOptions.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
        builder.addCase(getClusterById.fulfilled, (state, action) => {
            state.selectedCluster = action.payload;
            return;
        });
    }
});

export const { handleSelectedCluster } = clusterSlice.actions;

export default clusterSlice.reducer;
