import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';
import queryString from 'query-string';

export const getTimingSlot = createAsyncThunk('timingSlotSlice/getTimingSlot', async () => {
    try {
        let response = await axios.get('/timing-slot', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getTimingSlotOptions = createAsyncThunk('timingSlotSlice/getTimingSlotOptions', async (data = {}) => {
    try {
        // showLoader(true);

        data.status = 1;
        data.option = 'true';
        const stringified = queryString.stringify(data, { arrayFormat: 'bracket' });

        let response = await axios.get('/timing-slot?' + stringified, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        console.log(err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createTimingSlot = createAsyncThunk('timingSlotSlice/createTimingSlot', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/timing-slot', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Timing Slot added successfully.');
        dispatch(getTimingSlot());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getTimingSlotById = createAsyncThunk('timingSlotSlice/getTimingSlotById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/timing-slot/' + id);

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateTimingSlot = createAsyncThunk('timingSlotSlice/updateTimingSlot', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/timing-slot/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Timing Slot updated successfully.');
        dispatch(getTimingSlot());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteTimingSlot = createAsyncThunk('timingSlotSlice/deleteTimingSlot', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/timing-slot/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Timing Slot deleted successfully.');
        dispatch(getTimingSlot());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('timingSlotSlice/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/timing-slot/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getTimingSlot());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const timingSlotSlice = createSlice({
    name: 'timingSlotSlice',
    initialState: {
        data: [],
        options: [],
        selectedTimingSlot: {}
    },
    reducers: {
        handleSelectedTimingSlot: (state, action) => {
            state.selectedTimingSlot = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTimingSlot.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getTimingSlotOptions.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
        builder.addCase(getTimingSlotById.fulfilled, (state, action) => {
            state.selectedTimingSlot = action.payload;
            return;
        });
    }
});

export const { handleSelectedTimingSlot } = timingSlotSlice.actions;

export default timingSlotSlice.reducer;
