import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

import queryString from 'query-string';

export const getFaculty = createAsyncThunk('facultySlice/getFaculty', async () => {
    try {
        let response = await axios.get('/faculty', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getFacultyOptions = createAsyncThunk('facultySlice/getFacultyOptions', async (data = {}) => {
    try {
        showLoader(true);

        data.status = 1;
        data.option = 'true';
        const stringified = queryString.stringify(data, { arrayFormat: 'bracket' });

        let response = await axios.get('/faculty?' + stringified, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createFaculty = createAsyncThunk('facultySlice/createFaculty', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/faculty', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Faculty added successfully.');
        dispatch(getFaculty());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getFacultyById = createAsyncThunk('facultySlice/getFacultyById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/faculty/' + id);

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateFaculty = createAsyncThunk('facultySlice/updateFaculty', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/faculty/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Faculty updated successfully.');
        dispatch(getFaculty());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteFaculty = createAsyncThunk('facultySlice/deleteFaculty', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/faculty/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Faculty deleted successfully.');
        dispatch(getFaculty());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('facultySlice/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/faculty/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getFaculty());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return false;
    }
});

export const facultySlice = createSlice({
    name: 'facultySlice',
    initialState: {
        data: [],
        options: [],
        selected: {}
    },
    reducers: {
        handleSelectedFaculty: (state, action) => {
            state.selected = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getFaculty.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getFacultyOptions.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
        builder.addCase(getFacultyById.fulfilled, (state, action) => {
            state.selectedFaculty = action.payload;
            return;
        });
    }
});

export const { handleSelectedFaculty } = facultySlice.actions;

export default facultySlice.reducer;
