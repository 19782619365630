// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import permission from './permission';
import users from '../views/Setup/User/store';
import roles from '../views/Setup/RoleRights/Role/store';
import clusters from '../views/Master/Cluster/store';
import branch from '../views/Master/Branch/store';
import batchType from '../views/Master/BatchType/store';
import subject from '../views/Master/Subject/store';
import board from '../views/Master/Board/store';
import standard from '../views/Master/Standards/store';
import timingSlot from '../views/Master/TimingSlot/store';
import department from '../views/Master/Department/store';
import room from '../views/Master/Room/store';
import batch from '../views/Batch/store';
import timetable from '../views/TimetableGrid/store';
import faculty from '../views/Master/Faculty/store';
import moduleSlice from '../views/Setup/ModuleManage/store';

const rootReducer = {
    auth,
    navbar,
    layout,
    permission,
    users,
    roles,
    moduleSlice,
    clusters,
    branch,
    subject,
    batchType,
    board,
    standard,
    timingSlot,
    department,
    room,
    batch,
    timetable,
    faculty
};

export default rootReducer;
