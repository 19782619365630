import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';
import queryString from 'query-string';

export const getStandard = createAsyncThunk('standardSlice/getStandard', async () => {
    try {
        let response = await axios.get('/standard', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getStandardOptions = createAsyncThunk('standardSlice/getStandardOptions', async (data = {}) => {
    try {
        // showLoader(true);

        data.status = 1;
        data.option = 'true';
        const stringified = queryString.stringify(data, { arrayFormat: 'bracket' });

        let response = await axios.get('/standard?' + stringified, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createStandard = createAsyncThunk('standardSlice/createStandard', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/standard', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Standard added successfully.');
        dispatch(getStandard());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getStandardById = createAsyncThunk('standardSlice/getStandardById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/standard/' + id);

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStandard = createAsyncThunk('standardSlice/updateStandard', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/standard/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Standard updated successfully.');
        dispatch(getStandard());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStandardSubjects = createAsyncThunk('standardSlice/updateStandardSubjects', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/standard/board/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Standard updated successfully.');
        dispatch(getStandardById(data.id));

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteStandard = createAsyncThunk('standardSlice/deleteStandard', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/standard/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Standard deleted successfully.');
        dispatch(getStandard());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('standardSlice/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/standard/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getStandard());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return false;
    }
});

export const standardSlice = createSlice({
    name: 'standardSlice',
    initialState: {
        data: [],
        options: [],
        selectedStandard: {}
    },
    reducers: {
        handleSelectedStandard: (state, action) => {
            state.selectedStandard = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getStandard.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getStandardOptions.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
        builder.addCase(getStandardById.fulfilled, (state, action) => {
            state.selectedStandard = action.payload;
            return;
        });
    }
});

export const { handleSelectedStandard } = standardSlice.actions;

export default standardSlice.reducer;
