import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

// Loader Components
import showLoader from '@components/loader';

export const getBoard = createAsyncThunk('boardSlice/getBoard', async () => {
    try {
        let response = await axios.get('/board', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBoardOptions = createAsyncThunk('boardSlice/getBoardOptions', async (data = {}) => {
    try {
        // showLoader(true);

        data.status = 1;
        data.option = 'true';
        const stringified = queryString.stringify(data, { arrayFormat: 'bracket' });

        let response = await axios.get('/board?' + stringified, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBoard = createAsyncThunk('boardSlice/createBoard', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/board', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Board added successfully.');
        dispatch(getBoard());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBoardById = createAsyncThunk('boardSlice/getBoardById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/board/' + id);

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBoard = createAsyncThunk('boardSlice/updateBoard', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/board/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Board updated successfully.');
        dispatch(getBoard());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteBoard = createAsyncThunk('boardSlice/deleteBoard', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/board/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Board deleted successfully.');
        dispatch(getBoard());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('boardSlice/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/board/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getBoard());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const boardSlice = createSlice({
    name: 'boardSlice',
    initialState: {
        data: [],
        options: [],
        selectedBoard: {}
    },
    reducers: {
        handleSelectedBoard: (state, action) => {
            state.selectedBoard = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getBoard.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getBoardOptions.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
        builder.addCase(getBoardById.fulfilled, (state, action) => {
            state.selectedBoard = action.payload;
            return;
        });
    }
});

export const { handleSelectedBoard } = boardSlice.actions;

export default boardSlice.reducer;
